<template>
    <section id="BloquedosPrincipal">
      <section class="scroll_central">  
        <img loading="lazy" class="scroll_central__flecha" src="https://d3jotkb1j9zbaa.cloudfront.net/flecha-abajo.svg" alt="Icono flecha abajo" title="Icono flecha abajo">
        <div class="scroll_central__progress_bar">
          <span class="scroll_central__circulo"></span>
          <span class="scroll_central__progress_bar--rojo"></span>
          <svg viewBox="0 0 24 24" class="scroll_central__play"><path d="m8.016 5.019 10.969 6.984-10.969 6.984V5.018z"/></svg>
          <svg viewBox="0 0 29.9 29.9" class="scroll_central__flor"><path d="M19.6 15.6c.1 2.5-1.8 4.6-4.1 4.8-2.6.2-4.8-1.6-5.1-4.1-.1-1.2.1-2.4.7-3.5.9-1.7 2.2-3 3.8-3.9.1 0 .2-.1.2 0 1.9 1.1 3.4 2.7 4.2 4.8.2.7.3 1.3.3 1.9zm-5.2 3.6v-1.4c0-.1 0-.2-.1-.3-.9-.7-1.7-1.4-2.6-2l-.1-.1c-.3 2 1.1 3.6 2.8 3.8zm1.2 0c1.8-.3 3.1-2.1 2.9-3.8l-2.7 2.1s-.1.1-.1.2c-.1.5-.1 1-.1 1.5zm-3.9-5.1 2.7 2.1v-1.3c0-.2.1-.5 0-.7-.1-.2-.4-.3-.6-.5-.4-.3-.9-.7-1.3-1-.3.4-.6.9-.8 1.4zm3.9 2.1c.1-.1.1-.1.2-.1.7-.6 1.4-1.1 2.1-1.6.4-.3.4-.3.2-.7-.2-.4-.4-.7-.5-1.1-.7.5-1.3 1-1.9 1.5l-.1.1v1.9zm0-3.6c.4-.3.8-.6 1.2-1-.4-.4-.8-.8-1.2-1.1v2.1zm-1.2 0v-2.1l-1.2 1.2 1.2.9z"/></svg>
        </div>
      </section> 
      <BloquedosSecciones/>
    </section>
</template>

<script>
import { defineAsyncComponent } from "vue";
export default {
  name: 'Bloquedos',
  components: {
    BloquedosSecciones:defineAsyncComponent(() =>
      import(/* webpackChunkName: "BloquedosSecciones" */ "./bloqueDosSecciones/BloqueDosSeciones.vue")
    ),
  }
}
</script>
